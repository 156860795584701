window.esmsInitOptions = {
    onerror: error => {
        console.error(error);
        const scriptElement = document.createElement('script');
        scriptElement.type = 'importmap-shim';
        // Todo: update the url to use prod import maps
        scriptElement.src = `${window.__ROOT_CONFIG_ENV__.CDN_URL}/import-maps/import-maps.json`;
        document.head.appendChild(scriptElement);
    }
};
